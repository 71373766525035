<template>
  <div class="wrapper">
    <div class="header">
    </div>
    <div class="content">
      <div class="hot">
        <div class="title">热门问题</div>
        <div class="q-list">
          <div v-for="(item, index) in hots" :key="index">
            <div
                @click="openDetail(index, 'hot')"
                class="item f-row f-justify-between f-align-center"
            >
              <div class="f-row f-align-center">
                <span class="block"></span>
                <span class="txt">{{ item.title }}</span>
              </div>
              <span class="arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="cat">
        <div class="title">
          <div class="nav-item" v-for="(item, index) in cates" :key="index">
                        <span
                            @click="num = index"
                            :class="{ active: num == index }"
                        >{{ item.title }}</span
                        >
          </div>
        </div>
        <div v-for="(item, index) in cates" :key="index">
          <div class="q-list" v-show="num == index">
            <div v-for="(list, k) in item.list" :key="k">
              <div
                  @click="openDetail(k, item.type)"
                  class="item f-row f-justify-between f-align-center"
              >
                <div class="f-row f-align-center">
                  <span class="block"></span>
                  <span class="txt">{{ list.title }}</span>
                </div>
                <span class="arrow-right"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-none"></div>
    <div @click="jumpContact" class="contact">
      <div class="btn f-row f-justify-start f-align-start">

      </div>
    </div>
  </div>
</template>

<script>
import { cate, hot } from './data'
import { executeInteraction } from '@/utils/bridge'
import { getUrlQueryString } from '@/utils/util'
export default {
  name: 'helpCenter',
  components: { },
  props: {},
  data() {
    return {
      num: 0,
      cates: cate,
      hots: hot
    }
  },
  computed: {},
  watch: {},
  created() {
    const index = getUrlQueryString("index")
    console.log(index)
    if (index != "undefined" && index != null) {
      this.num = index
    }
  },
  mounted() {},
  methods: {
    openDetail(id, type) {
      this.$router.push({path: '/content/' + id + '/' + type});
    },
    jumpContact() {
      let param = {
        url: 'ticket://mine/custom'
      }
      executeInteraction('route', JSON.stringify(param))
    }
  }
}
</script>

<style lang="less">

.wrapper {
  .header {
    position: relative;
    font-size: 0;
    padding: 10px 40px 10px 40px;
    .bg {
      display: block;
      width: 100%;
    }
  }
  .content {
    padding: 10px 40px 10px 40px;
    .hot {
      margin-top: 15px;
      .title {
        margin-bottom: 52px;
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #82403e;

      }
      .q-list {
        background-color: #fff;
        padding: 45px 30px;
        border-radius: 30px;
        border: 1px solid #fff;
        .item {
          padding: 0 0 52px;
          .block {
            width: 23px;
            height: 23px;
            background: linear-gradient(
                270deg,
                #ababab 0%,
                #f7f7f7 100%
            );
            border-radius: 100%;
            margin-right: 26px;
          }
          .txt {
            font-size: 40px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #82403e;
          }
          .arrow-right {
            border-top: 2px solid #ababab;
            border-right: 2px solid #ababab;
            width: 12px;
            height: 12px;
            margin-right: 26px;
            transform: rotate(45deg);
          }
        }
      }
    }
    .cat {
      margin-top: 65px;
      .nav-item {
        display: inline-block;
        margin-top: 20px;
      }
      .title {
        text-align: left;
        margin-bottom: 52px;
        span {
          margin-right: 60px;
          margin-bottom: 52px;
          font-size: 46px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: #82403e;
        }
        .active {
          font-size: 46px;
          font-weight: 600;
          padding-bottom: 10px;
          text-decoration: none;
          border-bottom: 10px solid #f06c3c;
        }
      }
      .q-list {
        background-color: #fff;
        padding: 45px 30px;
        border-radius: 30px;
        border: 1px solid #fff;
        .item {
          padding: 0 0 52px;
          .block {
            width: 23px;
            height: 23px;
            background: linear-gradient(
                270deg,
                #ababab 0%,
                #f7f7f7 100%
            );
            border-radius: 100%;
            margin-right: 26px;
          }
          .txt {
            font-size: 40px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #82403e;
          }
          .arrow-right {
            border-top: 2px solid #ababab;
            border-right: 2px solid #ababab;
            width: 12px;
            height: 12px;
            margin-right: 26px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .foot-none {
    width: 100%;
    height: 185px;
  }
  .contact {
    position: fixed;
    left: calc(0% - 0px);
    bottom: 61px;

    .btn {
      width: 399px;
      height: 264px;
      img {
        width: 399px;
      }
    }
  }
}
</style>