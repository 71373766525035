
const cate = [
    {
      type: '2',
      title: '充值问题',
      list: [
        {
          title: '1. 钻石有什么用，怎么获得？',
          content: [
            '答：钻石可以进行文字聊天、语音、视频通话等，给您心仪的对象增加彼此亲密度。可以进入“我的-钻石充值”进行充值获得。'
          ]
        },
        {
          title: '2. 为什么充值了还不到账？',
          content: [
              '答：一般充值虚拟钻石成功后，预计10分钟内到账，请您耐心等待。充值成功后可以通过“我的-我的钻石-收支明细”查看充值明细和钻石余额。如果发现仍未到账，可通过"人工客服"联系我们。'
          ]
        },
        {
          title: '3. 充值失败、充值限额怎么办？',
          content: [
            '答：您可以先查询支付宝、微信或付款银行卡余额是否充足，或者更换其他支付方式重新充值即可。若仍然无法充值，可以联系第三方支付平台客服进行查询支付情况，也可以通过点击“我的-在线客服”直接联系佳伴APP人工客服帮助您解决充值失败问题'
          ]
        },
        {
          title: '4. 充值的钻石支持退款吗？',
          content: [
            '答：平台虚拟产品一经充值成功，暂不支持退款，实在抱歉。'
          ]
        },
        {
          title: '5. 怎么查看我的消费明细？',
          content: [
            '答：进入“我的-我的钻石”，点击右上角有个【收支明细】，可查看消费记录。',
          ]
        },
      ]
    },
    {
      type: '3',
      title: '提现问题',
      list: [
        {
          title: '1. 怎么样可以提现？',
          content: [
           '答：可以到“我的-我的积分”，选择合适积分兑换档位，点击兑换即可提现。'
          ]
        },
        {
          title: '2. 提现多久可以到账？',
          content: [
            '答：一般审核时间24小时，如有超过24小时情况未到账，可通过“人工客服”’提供订单金额和时间,我们工作人员会进一步核实。'
          ]
        },
        {
          title: '3. 提现失败的积分会不见吗？',
          content: [
           '答：正常情况下，提现失败的积分，系统会原路退还给用户。如提现成功出现未到账情况，可通过"人工客服"联系我们。'
          ]
        },
        {
          title: '4. 提现提示需要认证？',
          content: [
              '答：在申请提现前，您的手机需要安装支付宝并且手机号和实名认证都通过后才能进行提现操作。'
          ]
        },
        {
          title: '5. 如何更改手机号、微信、支付宝？',
          content: [
              '答：抱歉，我们暂不支持更改绑定的手机号、微信、支付宝的。'
          ]
        },
        {
          title: '6. 账号被禁用，还能提现到账吗？',
          content: [
            '答：抱歉，账号被封禁期间是无法进行提现的。如有疑问，可以联系客服处理。'
          ]
        }
      ]
    },
    {
      type: '4',
      title: '认证问题',

      list: [
        {
          title: '1. 如何获得真人标签？',
          content: [
            '答：首先，点击“我的-个人主页-编辑”进入修改资料页面先上传一张本人真实照片，然后再点击“我的-真人认证”，按照提示进行操作，提交后耐心等待5-20分钟审核。认证成功后您的昵称旁边就能够获得【真人】标签啦。'
          ]
        },
        {
          title: '2. 真人认证后，可以换头像吗',
          content: [
            '答：可以哦。真人认证成功后，每次更换头像，都将新头像与上次认证成功时保存的照片进行比对。',
            '如果比对成功，则保留当前的「真人」标签。',
            '如果对比失败，则弹出重新认证头像弹窗，此时您取消认证的话，则重新变为未认证状态。'
          ]
        },
        {
          title: '3. 真人认证审核需要多久？',
          content: [
              '答：真人认证提交后，审核时长一般5-20分钟，请耐心等待。'
          ]
        },
        {
          title: '4. 实名认证怎么操作？',
          content: [
              '答：点击“我的-实名认证“即可进入认证页面，请按照提示进行操作，填入相应信息后进行实名认证。'
          ]
        },
        {
          title: '5. 实名认证不了怎么办？',
          content: [
              '答：如果您实名不了，请您确认您是否未满18岁，填入的身份证号码或姓名是否有误，支付宝是否有安装，或者确认支付宝人脸识别过程中没被遮挡脸部。'
          ]
        },{
          title: '6. 如何手机认证？',
          content: [
              '答：如果您是手机登录的，则已自动完成手机认证；如果微信登录用户，可以点击“我的-手机认证“，按照指引输入手机号码完成短信验证后，即可成功认证。'
          ]
        },

      ]
    },
  {
    type: '5',
    title: '聊天问题',
    list: [
      {
        title: '1. 亲密度是什么？',
        content: [
          '答：亲密度代表双方的关系程度，亲密度越高越有爱，聊天、语音、视频都可以提升亲密度。'
        ]
      },
      {
        title: '2. 如何设置收费？',
        content: [
          '答：佳伴秉承平台公平公正原则，聊天收费或免费的功能权限完全交由用户自主设置；',
          '通过【我的】-【设置】-【收费设置】可设置免费聊天或不同的收费标准；',
          '当设置聊天付费时候，30分钟内不回复则钻石退还至账户，互相关注的情况下双方文字聊天免费。'
        ]
      },
      {
        title: '3. 怎么关注对方？',
        content: [
          '答：进入对方资料信息页面，有个"关注Ta"按钮，当双方互相关注情况下，显示互相关注，此时双方文字聊天免费。'
        ]
      },
      {
        title: '4. 怎么拉黑对方？',
        content: [
          '答：进入对方的聊天框内，点击右上角“...”，选择加入黑名单后将无法接收对方的消息。'
        ]
      }
    ]
  }
]

// const hot = []
// cate.forEach(item => {
//     item.list.forEach(item2 => {
//         if (item2.isHot) {
//             hot.push(item2)
//         }
//     })
// })
const hot =   [
        {
          isHot: true,
          title: '1.聊天要收费吗?',
          content: [
            '答：只有对方设置了私信收费，文字聊天才需要付费。另外在系统有赠送足够的免费钻石，或双方相互关注时，私信聊天免费。'
          ]
        },
        {
          isHot: true,
          title: '2.如何获得钻石?',
          content: [
            '答：新注册用户会获得一定钻石体验，每日签到可免费获得钻石；您的积分累积到一定程度可以兑换相对应的钻石。'
          ]
        },
        {
          isHot: true,
          title: '3.为什么我的账号被封禁冻结了？',
          content: [
            '答：账号封禁冻结一般有以下几种情况，',
            '(1)传播淫秽色情内容；' ,
            '(2)存在骗取钱财、信息为目的的欺诈性社交行为；',
            '(3)传播、售卖为导向的广告行为；' ,
            '(4)存在不良社交行为；',
            '(5)违反国家现行法律法规，侵害佳伴合法权益。'
          ]
        },
        {
          isHot: true,
          title: '4.如何举报？',
          content: [
            '答：您可以选择要举报的用户头像，进入用户详情页，点击右上角"..."选择举报即可。还请提供详细完整的证据截图，以便工作人员查询核实。'
          ]
        },
        {
          isHot: true,
          title: '5.如何注销账号？',
          content: [
            '答：请您更新佳伴最新版本，点击右下角“我的”-"设置"，下方有个删除账号。',
            ' 请您注销前注意以下问题：',
            ' 1.注销后账号所有资料将丢失，无法恢复。',
            ' 2.没使用的钻石和积分将自动清零不予退还。',
            ' 如确认没问题您可以按照以上说的进行操作。'
          ]
        },
        {
          isHot: true,
          title: '6.还有其他的疑问或者建议，怎么办？',
          content: [
            ' 答：（1)可通过【我的】-【产品建议】，反馈给佳伴官方',
            '（2)可通过【我的】-【人工客服】 进行咨询',
            '客服服务时间：周一至周五 9:00～22:00',
            '周末工作时间 9:00～12:00 13:30～18:30',
          ]
        }
      ];
export { cate, hot }
