/**
 * 与客户端交互
 */

/**
 * 执行与客户端交互
 * @param {*} method 方法名
 * @param {*} paramStr 参数字符串
 * @param {*} isReturn 是否有返回值
 */
const executeInteraction = (method, paramStr = '', isReturn = false) => {
    let ret = null
    if (paramStr !== '') {
        ret = window.JQSJ && window.JQSJ[method] && window.JQSJ[method](paramStr)
    } else {
        ret = window.JQSJ && window.JQSJ[method] && window.JQSJ[method]()
    }
    if (isReturn) {
        return ret
    }
}

export { executeInteraction }
